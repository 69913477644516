import { template } from "@ember/template-compiler";
import Component from "@glimmer/component";
import { action } from "@ember/object";
import { inject as service } from "@ember/service";
import DButton from "discourse/components/d-button";
import { ajax } from "discourse/lib/ajax";
import { popupAjaxError } from "discourse/lib/ajax-error";
import { getPasskeyCredential } from "discourse/lib/webauthn";
export default class PasskeyLoginButton extends Component {
    @service
    dialog;
    @action
    async passkeyLogin() {
        try {
            const response1 = await ajax("/session/passkey/challenge.json");
            const publicKeyCredential1 = await getPasskeyCredential(response1.challenge, (errorMessage1)=>this.dialog.alert(errorMessage1));
            const authResult1 = await ajax("/session/passkey/auth.json", {
                type: "POST",
                data: {
                    publicKeyCredential: publicKeyCredential1
                }
            });
            if (authResult1 && !authResult1.error) {
                window.location.reload();
            } else {
                this.dialog.alert(authResult1.error);
            }
        } catch (e1) {
            popupAjaxError(e1);
        }
    }
    static{
        template(`
    <DButton
      @action={{this.passkeyLogin}}
      @icon="user"
      @label="login.passkey.name"
      class="btn btn-social passkey-login-button"
    />
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
